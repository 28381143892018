import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { css } from "@emotion/core"

import resumePDF from "../files/Resume - Reino S Muhl.pdf"
import resumeIcon1 from "../images/curriculum-svgrepo-com.svg"

export default () => (
  <Layout>
    <SEO title="About" />
    <div>
      <h1>About me</h1>
      <p>
        You probably already know that I'm a fullstack JavaScript developer, but that's not all I can do.
      </p>
      <p>
        For a complete list of languages, frameworks and tools I'm proficient in, have a look at my <Link to="/uses/"><strong>Uses</strong></Link> page.
      </p>
      <p>
        Before deciding to go freelance, I held the position of Senior Developer consultant and I was the manager of 
        4 developer consultants.
      </p>
      <p>
        During my 8 years as a consultant Developer I mainly worked directly with clients 
        to create custom solutions for Enterprise HR software solutions, such as SAP on-premise, SuccessFactors and Lifion by ADP.
      </p>
      <p>
        I'm South African currently living in Brooklyn, New York City, as a legal permanent resident (green card holder) of the US.
      </p>
      <h2>Resume</h2>
      <p>Feel free to download my resume below for more background information.
        <br />
        <a href={resumePDF} css={css`
            background-image: none;
          `}
        >
          <img css={css`
              height: 50px;
              margin-bottom: 0px;
              margin-top: 10px;
              text-shadow: none;
            `} 
            src={resumeIcon1} alt="Resume - Reino Muhl" 
          />
          <br />
          <strong><u>Download</u></strong>
        </a>
      </p>
    </div>
  </Layout>
)